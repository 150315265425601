/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getLocationsCountries,
  getLocationRegion,
  getLocationLocality,
  getLocationLocalityById,
  getLocationDemandRoute,
} from '@api/routes/locations';

import { sendLog } from '@utils/SentryError';

import server from '../../api';

interface SearchLocalitiesFilter {
  field: string;
  value: string;
  isActive?: boolean;
}

export const initialState = {
  countries: [],
  regions: [],
  localities: [],
  localityById: {},
  demand: undefined,
  loading: false,
  loadingRegions: false,
  loadingLocalities: false,
  error: {},
};

const setLocations = (state, action, type) => {
  state[type] = action.payload?.data ?? [];
};

export const getCountries = createAsyncThunk(
  'location/getCountries',
  async ({ isActive = true }: { isActive?: boolean }) => {
    const response = await server({
      method: 'get',
      url: getLocationsCountries(),
      params: {
        isActive,
      },
    });
    return response.data;
  }
);

export const getRegions = createAsyncThunk(
  'location/getRegions',
  async ({ countryIso, isActive = true }: { countryIso: string; isActive?: boolean }) => {
    const response = await server({
      method: 'get',
      url: getLocationRegion(countryIso),
      params: { countryIso, isActive },
    });
    return response.data;
  }
);

export const getLocalities = createAsyncThunk(
  'location/getLocalities',
  async ({ field, value, isActive = true }: SearchLocalitiesFilter) => {
    const response = await server({
      method: 'get',
      url: getLocationLocality(),
      params: { isActive, [field]: value },
    });
    return response.data;
  }
);

export const getLocalityById = createAsyncThunk(
  'location/getLocalityById',
  async (locality: string) => {
    const response = await server({
      method: 'get',
      url: getLocationLocalityById(locality),
    });
    return response.data;
  }
);

export const getLocationDemand = createAsyncThunk('center/getLocationDemand', async () => {
  const response: any = await server.get(getLocationDemandRoute());
  return response.data;
});

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
  extraReducers: {
    [String(getCountries.fulfilled)]: (state, action) => setLocations(state, action, 'countries'),
    [String(getCountries.rejected)]: (state, action) =>
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-LOCATION_SLICE-18'),

    [String(getLocalityById.fulfilled)]: (state, action) => {
      state.localityById = action.payload;
    },
    [String(getLocalityById.rejected)]: (state, action) => {
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-LOCATION_SLICE-70');

      return state;
    },

    [String(getRegions.fulfilled)]: (state, action) => {
      setLocations(state, action, 'regions');
      state.loadingRegions = false;
    },
    [String(getRegions.pending)]: (state) => {
      state.loadingRegions = true;
    },
    [String(getRegions.rejected)]: (state, action) => {
      state.loadingRegions = false;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-LOCATION_SLICE-26');
    },

    [String(getLocalities.fulfilled)]: (state, action) => {
      setLocations(state, action, 'localities');
      state.loadingLocalities = false;
    },
    [String(getLocalities.pending)]: (state) => {
      state.loadingLocalities = true;
    },
    [String(getLocalities.rejected)]: (state, action) => {
      state.loadingLocalities = false;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-LOCATION_SLICE-37');
    },

    [String(getLocationDemand.rejected)]: (state, action) => {
      state.demand = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-LOCATION_SLICE-166');
    },
    [String(getLocationDemand.fulfilled)]: (state, action) => {
      state.demand = action.payload.data;
    },
  },
});

export const { setLoading } = locationSlice.actions;

export default locationSlice.reducer;
