/* eslint-disable import/prefer-default-export */
export enum RequestStatusEnum {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum MarketingKeysEnum {
  CAMPAIGN = 'utm_campaign',
  CONTENT = 'utm_content',
  MEDIUM = 'utm_medium',
  SOURSE = 'utm_source',
  TERM = 'utm_term',
}

export enum SummaryStatusEnum {
  BLOCKED = 'blocked',
  COMPLETED = 'completed',
  IN_PROCESS = 'in_process',
  IN_REVIEW = 'in_review',
  OBJECTED = 'objected',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export enum OperativeSystemEnum {
  IOS = 'ios',
  ANDROID = 'android',
  ANY = 'any',
}

export enum CountryEnum {
  CL = 'CL',
  CO = 'CO',
  MX = 'MX',
  PE = 'PE',
}

export enum RequirementsEnum {
  PPE = 'ppe',
  DOCS = 'docs',
  SPECIFIC = 'specific',
}

export enum VehicleDocumentKeyCodeMatchEnum {
  'car_registration_certificate' = 'carRegistrationCertificate',
  'motorcycle_registration_certificate' = 'motorcycleRegistrationCertificate',
  'moped_registration_certificate' = 'mopedRegistrationCertificate',
  'van_registration_certificate' = 'vanRegistrationCertificate',
  'car_driver_license' = 'carDriverLicense',
  'motorcycle_driver_license' = 'motorcycleDriverLicense',
  'moped_driver_license' = 'mopedDriverLicense',
  'van_driver_license' = 'vanDriverLicense',
  'car_ownership_card' = 'carOwnershipCard',
  'motorcycle_ownership_card' = 'motorcycleOwnershipCard',
  'moped_ownership_card' = 'mopedOwnershipCard',
  'van_ownership_card' = 'vanOwnershipCard',
  'car_soat' = 'carSoat',
  'motorcycle_soat' = 'motorcycleSoat',
  'moped_soat' = 'mopedSoat',
  'van_soat' = 'vanSoat',
  'motorcycle_road_worthiness_test' = 'motorcycleRoadWorthinessTest',
  'van_road_worthiness_test' = 'vanRoadWorthinessTest',
  'car_road_worthiness_test' = 'carRoadWorthinessTest',
  'furgon_driver_license' = 'furgonDriverLicense',
  'furgon_registration_certificate' = 'furgonRegistrationCertificate',
  'car_insurance' = 'carInsurance',
}

export enum ExtraDocsEnum {
  'vaccination_card' = 'vaccinationCard',
  'food_handling_certificate' = 'foodHandlingCertificate',
  'eps_certificate' = 'epsCertificate',
  'pension_certificate' = 'pensionCertificate',
  'health_card_with_food_handling' = 'healthCardWithFoodHandling',
  'suspension_certificate' = 'suspensionCertificate',
}

export enum vehiclesEnum {
  'car' = 'car',
  'moped' = 'moped',
  'van' = 'van',
  'motorcycle' = 'motorcicle',
  'truck' = 'truck',
  'furgon' = 'furgon',
}

export enum VehicleTypesSwitch {
  AUTO = 'car',
  VAN = 'van',
  MOTO = 'motorcycle',
  BICIMOTO = 'moped',
  TRUCK = 'truck',
  FURGON = 'furgon',
}

export enum VehicleDocumentKeyCodeSwitchEnum {
  CARREGISTRATIONCERTIFICATE = 'car',
  MOTORCYCLEREGISTRATIONCERTIFICATE = 'motorcycle',
  MOPEDREGISTRATIONCERTIFICATE = 'moped',
  VANREGISTRATIONCERTIFICATE = 'van',
  CARDRIVERLICENSE = 'car',
  MOTORCYCLEDRIVERLICENSE = 'motorcycle',
  MOPEDDRIVERLICENSE = 'moped',
  VANDRIVERLICENSE = 'van',
  CAROWNERSHIPCARD = 'car',
  MOTORCYCLEOWNERSHIPCARD = 'motorcycle',
  MOPEDOWNERSHIPCARD = 'moped',
  VANOWNERSHIPCARD = 'van',
  CARSOAT = 'car',
  MOTORCYCLESOAT = 'motorcycle',
  MOPEDSOAT = 'moped',
  VANSOAT = 'van',
  MOTORCYCLEROADWORTHINESSTEST = 'motorcycle',
  VANROADWORTHINESSTEST = 'van',
  CARROADWORTHINESSTEST = 'car',
  VACCINATIONCARD = 'certificates',
  FOODHANDLINGCERTIFICATE = 'certificates',
  EPSCERTIFICATE = 'certificates',
  PENSIONCERTIFICATE = 'certificates',
  HEALTHCARDWITHFOODHANDLING = 'certificates',
  CARINSURANCE = 'car',
  FURGONREGISTRATIONCERTIFICATE = 'furgon',
  FURGONDRIVERLICENSE = 'furgon',
}

export enum AuthRequestResponseEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  REJECTED = 'rejected',
}

export enum PaymentTypesEnum {
  'gross' = 'brutos',
  'liquid' = 'líquidos',
}

export enum DocumentsSubStatusEnum {
  EXPIRED = 'expired',
  TO_EXPIRE = 'toExpire',
  IN_FORCE = 'inForce',
}

// ACL - DATES

export enum IdentityCardTypesEnum {
  ID = 'id',
  VISA_APPLICATION = 'visa_application',
  PTP = 'PTP',
  PEP = 'PEP',
  FOREIGN_ID = 'foreign_ID',
  CPP = 'CPP',
}
enum DocumentTranslateEnum {
  ISSUE_DATE = 'issueDate',
  EXPIRATION_DATE = 'expirationDate',
}
export enum DocumentKeyCodeEnum {
  ID_CARD_FRONT = 'id_card_front',
  ID_CARD_REVERSE = 'id_card_reverse',
  PENSION_CERTIFICATE = 'pension_certificate',
  EPS_CERTIFICATE = 'eps_certificate',
  RUC_CERTIFICATE = 'ruc_certificate',
  SUSPENSION_CERTIFICATE = 'suspension_certificate',
}
enum ExtraDocumentsEnum {
  VACCINATION_CARD = 'vaccinationCard',
  FOOD_HANDLING_CERTIFICATE = 'foodHandlingCertificate',
  EPS_CERTIFICATE = 'epsCertificate',
  PENSION_CERTIFICATE = 'pensionCertificate',
  HEALTH_CARD_WITH_FOOD_HANDLING = 'healthCardWithFoodHandling',
}
export enum VehicleDocumentsEnum {
  CAR_REGISTRATION_CERTIFICATE = 'carRegistrationCertificate',
  MOTORCYCLE_REGISTRATION_CERTIFICATE = 'motorcycleRegistrationCertificate',
  MOPED_REGISTRATION_CERTIFICATE = 'mopedRegistrationCertificate',
  VAN_REGISTRATION_CERTIFICATE = 'vanRegistrationCertificate',
  CAR_DRIVER_LICENSE = 'carDriverLicense',
  MOTORCYCLE_DRIVER_LICENSE = 'motorcycleDriverLicense',
  MOPED_DRIVER_LICENSE = 'mopedDriverLicense',
  VAN_DRIVER_LICENSE = 'vanDriverLicense',
  CAR_OWNERSHIP_CARD = 'carOwnershipCard',
  MOTORCYCLE_OWNERSHIP_CARD = 'motorcycleOwnershipCard',
  MOPED_OWNERSHIP_CARD = 'mopedOwnershipCard',
  VAN_OWNERSHIP_CARD = 'vanOwnershipCard',
  CAR_SOAT = 'carSoat',
  MOTORCYCLE_SOAT = 'motorcycleSoat',
  MOPED_SOAT = 'mopedSoat',
  VAN_SOAT = 'vanSoat',
  MOTORCYCLE_ROAD_WORTHINESS_TEST = 'motorcycleRoadWorthinessTest',
  VAN_ROAD_WORTHINESS_TEST = 'vanRoadWorthinessTest',
  CAR_ROAD_WORTHINESS_TEST = 'carRoadWorthinessTest',
  FURGON_REGISTRATION_CERTIFICATE = 'furgonRegistrationCertificate',
  FURGON_DRIVER_LICENSE = 'furgonDriverLicense',
  CAR_INSURANCE = 'carInsurance',
}

export const translateDocumentKey = {
  [VehicleDocumentsEnum.CAR_REGISTRATION_CERTIFICATE]: 'car_registration_certificate',
  [VehicleDocumentsEnum.MOTORCYCLE_REGISTRATION_CERTIFICATE]: 'motorcycle_registration_certificate',
  [VehicleDocumentsEnum.MOPED_REGISTRATION_CERTIFICATE]: 'moped_registration_certificate',
  [VehicleDocumentsEnum.VAN_REGISTRATION_CERTIFICATE]: 'van_registration_certificate',
  [VehicleDocumentsEnum.CAR_DRIVER_LICENSE]: 'car_driver_license',
  [VehicleDocumentsEnum.MOTORCYCLE_DRIVER_LICENSE]: 'motorcycle_driver_license',
  [VehicleDocumentsEnum.MOPED_DRIVER_LICENSE]: 'moped_driver_license',
  [VehicleDocumentsEnum.VAN_DRIVER_LICENSE]: 'van_driver_license',
  [VehicleDocumentsEnum.CAR_OWNERSHIP_CARD]: 'car_ownership_card',
  [VehicleDocumentsEnum.MOTORCYCLE_OWNERSHIP_CARD]: 'motorcycle_ownership_card',
  [VehicleDocumentsEnum.MOPED_OWNERSHIP_CARD]: 'moped_ownership_card',
  [VehicleDocumentsEnum.VAN_OWNERSHIP_CARD]: 'van_ownership_card',
  [VehicleDocumentsEnum.CAR_SOAT]: 'car_soat',
  [VehicleDocumentsEnum.MOTORCYCLE_SOAT]: 'motorcycle_soat',
  [VehicleDocumentsEnum.MOPED_SOAT]: 'moped_soat',
  [VehicleDocumentsEnum.VAN_SOAT]: 'van_soat',
  [VehicleDocumentsEnum.MOTORCYCLE_ROAD_WORTHINESS_TEST]: 'motorcycle_road_worthiness_test',
  [VehicleDocumentsEnum.VAN_ROAD_WORTHINESS_TEST]: 'van_road_worthiness_test',
  [VehicleDocumentsEnum.CAR_ROAD_WORTHINESS_TEST]: 'car_road_worthiness_test',
  [VehicleDocumentsEnum.FURGON_REGISTRATION_CERTIFICATE]: 'furgon_registration_certificate',
  [VehicleDocumentsEnum.FURGON_DRIVER_LICENSE]: 'furgon_driver_license',
  [VehicleDocumentsEnum.CAR_INSURANCE]: 'car_insurance',
  [ExtraDocumentsEnum.VACCINATION_CARD]: 'vaccination_card',
  [ExtraDocumentsEnum.FOOD_HANDLING_CERTIFICATE]: 'food_handling_certificate',
  [ExtraDocumentsEnum.EPS_CERTIFICATE]: 'eps_certificate',
  [ExtraDocumentsEnum.PENSION_CERTIFICATE]: 'pension_certificate',
  [ExtraDocumentsEnum.HEALTH_CARD_WITH_FOOD_HANDLING]: 'health_card_with_food_handling',
};

const documentValidation = {
  records: {
    CL: { type: DocumentTranslateEnum.ISSUE_DATE, addQty: 6, addUnit: 'month' },
  },
  [DocumentKeyCodeEnum.ID_CARD_FRONT]: {
    CL: {
      [IdentityCardTypesEnum.ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.VISA_APPLICATION]: {
        type: DocumentTranslateEnum.ISSUE_DATE,
        addQty: 6,
        addUnit: 'month',
      },
    },
    PE: {
      [IdentityCardTypesEnum.ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.FOREIGN_ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.PTP]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    },
    CO: {
      [IdentityCardTypesEnum.ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.PEP]: {
        type: DocumentTranslateEnum.ISSUE_DATE,
        addQty: 2,
        addUnit: 'year',
      },
      [IdentityCardTypesEnum.FOREIGN_ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.PTP]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    },
  },
  [DocumentKeyCodeEnum.ID_CARD_REVERSE]: {
    CL: {
      [IdentityCardTypesEnum.ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.VISA_APPLICATION]: {
        type: DocumentTranslateEnum.ISSUE_DATE,
        addQty: 6,
        addUnit: 'month',
      },
    },
    PE: {
      [IdentityCardTypesEnum.ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.FOREIGN_ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.PTP]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    },
    CO: {
      [IdentityCardTypesEnum.ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.PEP]: {
        type: DocumentTranslateEnum.ISSUE_DATE,
        addQty: 2,
        addUnit: 'year',
      },
      [IdentityCardTypesEnum.FOREIGN_ID]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
      [IdentityCardTypesEnum.PTP]: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    },
  },
  [DocumentKeyCodeEnum.SUSPENSION_CERTIFICATE]: {
    PE: { type: DocumentTranslateEnum.ISSUE_DATE },
  },
  [ExtraDocumentsEnum.HEALTH_CARD_WITH_FOOD_HANDLING]: {
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.CAR_DRIVER_LICENSE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.MOTORCYCLE_DRIVER_LICENSE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.MOPED_DRIVER_LICENSE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.FURGON_DRIVER_LICENSE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.CAR_REGISTRATION_CERTIFICATE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.MOTORCYCLE_REGISTRATION_CERTIFICATE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.MOPED_REGISTRATION_CERTIFICATE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.FURGON_REGISTRATION_CERTIFICATE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.CAR_INSURANCE]: {
    CL: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.CAR_SOAT]: {
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.VAN_SOAT]: {
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.MOTORCYCLE_SOAT]: {
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [ExtraDocumentsEnum.FOOD_HANDLING_CERTIFICATE]: {
    PE: { type: DocumentTranslateEnum.EXPIRATION_DATE },
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.MOTORCYCLE_ROAD_WORTHINESS_TEST]: {
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.VAN_ROAD_WORTHINESS_TEST]: {
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
  [VehicleDocumentsEnum.CAR_ROAD_WORTHINESS_TEST]: {
    CO: { type: DocumentTranslateEnum.EXPIRATION_DATE },
  },
};

export const getDocumentDateCode = (
  code: string,
  country: string
): { type: DocumentTranslateEnum } | undefined => {
  return documentValidation[code]?.[country];
};

export enum VariableKindEnum {
  SIMPLE = 'simple',
  STAGGERED = 'staggered',
}
