export const changeEmailRoute = (): string => 'account/jobbers/change-no-active-email';

export const getOauthTokenRoute = (): string => 'auth/oauth/token';

export const resendCreatePswdEmailRoute = (): string => 'account/jobbers/resend-email';

export const resendEmailRoute = (): string => 'account/jobbers/resend-email';

export const postLogIn = (): string => '/account/users/sign-in';

export const sendRetreivePswdEmailRoute = (): string => '/account/jobbers/recover-password';

export const setNewPasswordRoute = (): string => 'account/jobbers/new-password';

export const setRetrievePasswordRoute = (): string => 'account/jobbers/reset-password';

export const logoutRoute = (): string => 'auth/oauth/revoke';

export const checkToken = (): string => 'auth/oauth/validate';
