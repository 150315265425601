/* eslint-disable no-underscore-dangle */
function lsTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function waitForLs(func) {
  if (!lsTest()) setTimeout(() => waitForLs(func), 100);
  else func();
}

const LocalStorageService = (function () {
  let _service: any;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj: { accessToken: string; refreshToken: string }) {
    localStorage.setItem('access_token', tokenObj.accessToken);
    localStorage.setItem('refresh_token', tokenObj.refreshToken);
  }
  function _setPublicToken(pubToken: string) {
    localStorage.setItem('pub_token', pubToken);
  }
  function _setItem(item: string, object: any) {
    localStorage.setItem(item, object);
  }
  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  function _getPublicToken() {
    return localStorage.getItem('pub_token');
  }
  function _getItem(item: string) {
    return localStorage.getItem(item);
  }
  function _setEmail(email: string) {
    return localStorage.setItem('email', email);
  }
  function _getEmail() {
    return localStorage.getItem('email');
  }
  function _cleanEmail() {
    localStorage.removeItem('email');
  }
  function _clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
  function _removeItem(item: string) {
    localStorage.removeItem(item);
  }
  function _clearOnLogout() {
    _clearToken();
    localStorage.removeItem('user');
    localStorage.removeItem('persist:root');
  }
  return {
    getService: _getService,
    setToken: (obj: { accessToken: string; refreshToken: string }) =>
      waitForLs(() => _setToken(obj)),
    setPublicToken: (token) => waitForLs(() => _setPublicToken(token)),
    setItem: (item: string, object: any) => waitForLs(() => _setItem(item, object)),
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getPublicToken: _getPublicToken,
    getItem: _getItem,
    removeItem: _removeItem,
    clearToken: _clearToken,
    cleanEmail: _cleanEmail,
    setEmail: _setEmail,
    getEmail: _getEmail,
    clearOnLogout: _clearOnLogout,
  };
})();

export default LocalStorageService;
