/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import LocalStorageService from '@utils/localStorage';
import Router from 'next/router';

import { registerApp } from '@store/auth/authSlice';
import store from '@store/index';
// eslint-disable-next-line import/extensions
import { version } from '../../package.json';

const BASE_URL_API = process.env.NEXT_PUBLIC_BASE_URL_API;

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// ! Crear la instancia de axios
const server = axios.create({
  baseURL: BASE_URL_API,
  headers: { 'Content-type': 'application/json', 'x-version': version },
});

// Add a request interceptor
server.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorageService.getAccessToken();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Requests queue
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Add a response interceptor
server.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && originalRequest.url === '/auth/oath/token') {
      Router.push('/auth/log-in');
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const response = await store.dispatch<any>(registerApp());
      if (response?.payload?.data?.accessToken) {
        processQueue(null, response.payload.data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${response.payload.data.accessToken}`;
        isRefreshing = false;
        return server(originalRequest);
      }
    }
    isRefreshing = false;
    processQueue(error, null);
    return Promise.reject(error);
  }
);

export default server;
