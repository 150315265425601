export const getLocationsCountries = (): string => 'locations/countries';

export const getLocationRegion = (countryIso: string): string =>
  countryIso === 'PE' ? 'locations/cities' : 'locations/regions';

export const getLocationLocality = (): string => 'locations/localities';

export const getLocationLocalityById = (localityId: string): string =>
  `locations/localities/${localityId}`;

export const getLocationDemandRoute = (): string => 'account/jobbers/location-demand';
