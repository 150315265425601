export enum CenterCategories {
  PERSONAL_DATA = 'personal_data',
  BANKING_DATA = 'banking_data',
  OTHER_DATA = 'other_data',
  RECORDS = 'records',
  OTHER_DOCUMENTS = 'other_documents',
  PROFILE_PHOTO = 'profile_photo',
  FRAMEWORK_AGREEMENT = 'framework_agreement',
  ADDITIONAL_INFORMATION = 'additional_information',
}

export enum StepsTabsEnum {
  PERSONAL_DATA = 'personal-data',
  BANK_DATA = 'bank-data',
  OTHER_DATA = 'other-data',
  RECORDS = 'records-data',
  DOCUMENTS = 'other-docs',
  ADDITIONAL_INFORMATION = 'additional-info',
}
export enum StepStatusEnum {
  COMPLETED = 'completed',
  IN_PROCESS = 'in_process',
  PENDING = 'pending',
  BLOCKED = 'blocked',
}

export enum VehicleEnum {
  Auto = 'auto',
  Van = 'van',
  Moto = 'moto',
  Bicimoto = 'bicimoto',
  Furgon = 'furgon',
  None = 'none',
}

export enum FileStatusEnum {
  APPROVED = 'approved',
  IN_REVIEW = 'in_review',
  OBJECTED = 'objected',
  GENERIC_ERROR = 'generic_error',
  OVERWEIGHT = 'overweight',
  INVALID_FORMAT = 'invalid_format',
  PENDING = 'pending',
  EXPIRED = 'expired',
  PENDING_EXPIRATION = 'pending_expiration',
  REJECTED = 'rejected',
  TEMP = 'temp',
}
