/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';

import server from '@api/index';
import {
  getJobberCenterSettingRoute,
  getJobberCenterSummary,
  getBanksRoute,
  bankDataRoute,
  personalDataRoute,
  profilePhotoRoute,
  recordsRoute,
  otherDocsRoute,
  getOtherDataRoute,
  ppeRoute,
  getAdditionalDocsRoute,
  vehiclesDocsRoute,
  getAvailableJobsRoute,
  uploadOtherDocsRoute,
} from '@api/routes/center';

import { getStatusFromString, uploadFiles, getFiles, clearState } from '@utils/functions';
import { sendLog } from '@utils/SentryError';
import { RequestStatusEnum, SummaryStatusEnum } from '@utils/enums';
import { PersonalData, BankData, OtherDataInterface, PutPpes } from './center';
import { CenterCategories, StepsTabsEnum } from './enums';
import { ConfirmActionProps } from '@components/templates/center/Resume/components/ConfirmAction';

const initialState = {
  centerSettingLoading: RequestStatusEnum.IDLE,
  error: {},
  rules: {},
  bankData: { defaultData: { address: {}, birthDate: '' }, loading: false, saving: false },
  personalData: {
    defaultData: { address: {}, birthDate: '' },
    loading: false,
    saving: false,
    documentNumberTaken: false,
  },
  otherData: { loading: false, defaultData: {}, responseLoading: false },
  profilePicture: { loading: false, file: undefined },
  records: { loading: false, file: undefined },
  otherDocs: { loading: false, files: undefined, ruc: undefined },
  ppes: {
    loading: false,
    status: RequestStatusEnum.IDLE,
    defaultData: { ppes: [], vehicleDocuments: [], extraDocuments: [] },
  },
  banks: [],
  bankLoading: false,
  toDoSummary: [],
  doneSummary: [],
  additionalInfo: {},
  allInfoFilledWithInReview: false,
  expiredCounters: undefined,
  availableJobs: { loading: true, jobs: [] },
  dataCompleted: false,
  selectedTab: undefined,
  confirmAction: undefined,
  alertTab: undefined,
  showButtonActions: false,
  categories: [],
};

export const getJobberCenterSetting = createAsyncThunk(
  'center/getCenterSetting',
  async (_, { getState }) => {
    const state: RootStateOrAny = getState();
    const response = await server.get(
      getJobberCenterSettingRoute(state.auth.user.countryIso || 'CL')
    );
    return response.data;
  }
);

export const getJobberSummary = createAsyncThunk(
  'center/getCenterSummary',
  async (_, { getState }) => {
    const response = await server.get(getJobberCenterSummary());
    const state: RootStateOrAny = getState();
    response.data.isJobber = state?.auth?.user?.roles?.includes('jobber');
    return response.data;
  }
);

export const getPersonalData = createAsyncThunk('center/getPersonalData', async () => {
  const response = await server.get(personalDataRoute());
  return response.data;
});

export const setPersonalData = createAsyncThunk(
  'center/setPersonalData',
  async (payload: PersonalData) => {
    const response = await server.put(personalDataRoute(), payload);
    return response.data;
  }
);

export const getBankData = createAsyncThunk('center/getBankData', async () => {
  const response = await server.get(bankDataRoute());
  return response.data;
});

export const setBankData = createAsyncThunk('center/setBankData', async (payload: BankData) => {
  const response = await server.put(bankDataRoute(), payload);
  return response.data;
});

export const getBanks = createAsyncThunk('center/getBanks', async (countryIso: string) => {
  const response = await server.get(getBanksRoute(countryIso));
  return response.data;
});

const factoryThunksUploadFile = (reducerRoute: string, backendRoute: string) =>
  createAsyncThunk(reducerRoute, async ({ file, ext }: { file: File; ext: string }) => {
    const response = await uploadFiles(file, ext, backendRoute);
    return response.data;
  });

const factoryThunksDeleteFile = (reducerRoute: string, backendRoute: string) =>
  createAsyncThunk(reducerRoute, async () => {
    const response = await server.delete(backendRoute);
    return response.data;
  });

export const setProfilePicture = factoryThunksUploadFile(
  'center/setProfilePicture',
  profilePhotoRoute()
);

export const getProfilePicture = createAsyncThunk('center/getProfilePicture', async () => {
  const response = await getFiles(profilePhotoRoute());
  return response.data;
});

export const deleteProfilePicture = factoryThunksDeleteFile(
  'center/deleteProfilePicture',
  profilePhotoRoute()
);

export const setRecords = factoryThunksUploadFile('center/setRecords', recordsRoute());

export const getRecords = createAsyncThunk('center/getRecords', async () => {
  const response = await server.get(recordsRoute());
  return response.data;
});

export const setOtherDocs = createAsyncThunk(
  'center/setOtherDoc',
  async (payload: { documents: Array<Record<'documents', any>> }) => {
    const response = await server.put(uploadOtherDocsRoute(), payload);
    return response.data;
  }
);

export const getOtherDocs = createAsyncThunk('center/getOtherDocs', async () => {
  const response = await server.get(otherDocsRoute());
  return response.data;
});

export const getOtherData = createAsyncThunk('center/getOtherData', async () => {
  const response = await server.get(getOtherDataRoute());
  return response.data;
});

export const putOtherData = createAsyncThunk(
  'center/putOtherData',
  async (payload: OtherDataInterface) => {
    const response = await server.put(getOtherDataRoute(), payload);
    return response.data;
  }
);

export const getAdditionalInfo = createAsyncThunk('center/getAdditionalInfo', async () => {
  const response = await server.get(getAdditionalDocsRoute());
  return response.data;
});

export const putPpes = createAsyncThunk('center/putPpes', async (payload: PutPpes) => {
  const response = await server.put(ppeRoute(), payload);
  return response.data;
});

export const setVehiclesDocs = createAsyncThunk(
  'center/setVehiclesDocs',
  async ({
    file,
    ext,
    code,
    category,
  }: {
    file: File;
    ext: string;
    code?: string;
    category?: string;
  }) => {
    const response = await uploadFiles(file, ext, vehiclesDocsRoute(code, category));
    return response.data;
  }
);

export const getAvailableJobs = createAsyncThunk('center/getAvailableJobs', async () => {
  const response = await server.get(getAvailableJobsRoute());
  return response.data;
});

export const uploadDocumentFile = createAsyncThunk(
  'center/uploadDocumentFile',
  async ({ body, route }: { body: Record<string, string>; route: string }) => {
    const response = await server.put(route, body);
    return response.data;
  }
);

const centerSlice = createSlice({
  name: 'center',
  initialState,
  reducers: {
    clearCenterData: () => ({ ...initialState }),
    clearDataCompleted: (state) => {
      state.dataCompleted = false;
    },
    clearStateCenter: clearState,
    setSelectedTab: (state, { payload }: { payload: StepsTabsEnum }) => {
      state.selectedTab = payload;
    },
    setConfirmModalAction: (state, { payload }: { payload: ConfirmActionProps }) => {
      state.confirmAction = payload;
    },
    setAlertTab: (state, { payload }: { payload: string | undefined }) => {
      state.alertTab = payload;
    },
    handleShowButtonActions: (state, { payload }: { payload: boolean }) => {
      state.showButtonActions = payload;
    },
  },
  extraReducers: {
    [String(getJobberSummary.rejected)]: (state, action) => {
      state.centerSettingLoading = RequestStatusEnum.ERROR;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-178');
    },
    [String(getJobberSummary.pending)]: (state) => {
      state.centerSettingLoading = RequestStatusEnum.PENDING;
    },
    [String(getJobberSummary.fulfilled)]: (state, action) => {
      const { categories } = action.payload.data;

      state.categories = categories.filter(
        (item) =>
          (!action.payload.isJobber || item.visibleOnJobber) &&
          (action.payload.isJobber || item.visibleOnApplicant)
      );

      const expiredCounters = categories
        .filter((item) => item?.summary?.statusCounter)
        .reduce(
          (prev, next) => {
            const counters = next?.summary?.statusCounter;
            prev.expired += counters?.expired || 0;
            prev.toExpire += counters?.toExpire || 0;
            return prev;
          },
          { expired: 0, toExpire: 0 }
        );

      const additionalInfoIndex = categories.findIndex(
        (elem) => elem.code === CenterCategories.ADDITIONAL_INFORMATION
      );
      const additionalInfo = categories[additionalInfoIndex];
      state.additionalInfo = additionalInfo ?? {};
      if (additionalInfoIndex !== -1) categories.splice(additionalInfoIndex, 1);
      state.centerSettingLoading = RequestStatusEnum.SUCCESS;
      state.rules = action.payload.data;
      const done = (item) =>
        item.summary.status === SummaryStatusEnum.COMPLETED ||
        item.summary.status === SummaryStatusEnum.IN_REVIEW ||
        (item.summary.status === SummaryStatusEnum.IN_PROCESS &&
          !item.summary.statusCounter.objected &&
          !item.summary.statusCounter.pending);

      state.doneSummary = categories.filter(
        (item) =>
          done(item) &&
          (!action.payload.isJobber || item.visibleOnJobber) &&
          (action.payload.isJobber || item.visibleOnApplicant)
      );
      state.toDoSummary = categories.filter(
        (item) =>
          !done(item) &&
          item.visibleOnApplicant &&
          (action.payload.isJobber || item.visibleOnApplicant)
      );
      const [inReview, other] = categories.reduce(
        (prev, item) => {
          if (SummaryStatusEnum.COMPLETED === item.summary.status) return prev;
          if (item.summary.status === SummaryStatusEnum.IN_REVIEW) return [prev[0] + 1, prev[1]];
          return [prev[0], prev[1] + 1];
        },
        [0, 0]
      );
      state.allInfoFilledWithInReview = !other && !!inReview;
      state.expiredCounters = expiredCounters;
    },

    [String(getPersonalData.rejected)]: (state, action) => {
      state.personalData.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-214');
    },
    [String(getPersonalData.pending)]: (state) => {
      state.personalData.loading = true;
    },
    [String(getPersonalData.fulfilled)]: (state, action) => {
      state.personalData.loading = false;
      delete action.payload.data.address.fullAddress;
      if (!action.payload.data.address.department) {
        action.payload.data.address = { ...action.payload.data.address, department: undefined };
      }
      state.personalData.defaultData = action.payload.data;
    },

    [String(getAvailableJobs.rejected)]: (state, action) => {
      state.availableJobs.loading = true;
      state.error = action.error;
    },
    [String(getAvailableJobs.pending)]: (state) => {
      state.availableJobs.loading = true;
    },
    [String(getAvailableJobs.fulfilled)]: (state, action) => {
      state.availableJobs.loading = false;
      state.availableJobs.jobs = action.payload.data;
    },

    [String(setPersonalData.rejected)]: (state, action) => {
      const status = getStatusFromString(action.error.message);
      state.personalData.saving = false;
      state.error = action.error;
      if (status === 409) state.personalData.documentNumberTaken = true;
      else sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-229');
    },
    [String(setPersonalData.pending)]: (state) => {
      state.personalData.saving = true;
      state.personalData.documentNumberTaken = false;
    },
    [String(setPersonalData.fulfilled)]: (state) => {
      state.personalData.saving = false;
      state.dataCompleted = true;
    },

    [String(getBankData.rejected)]: (state, action) => {
      state.bankData.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-243');
    },
    [String(getBankData.pending)]: (state) => {
      state.bankData.loading = true;
    },
    [String(getBankData.fulfilled)]: (state, action) => {
      state.bankData.loading = false;
      state.bankData.defaultData = action.payload.data;
    },

    [String(setBankData.rejected)]: (state, action) => {
      const status = getStatusFromString(action.error.message);
      state.bankData.saving = false;
      state.error = action.error;
      if (status !== 403) {
        sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-257');
      }
    },
    [String(setBankData.pending)]: (state) => {
      state.bankData.saving = true;
    },
    [String(setBankData.fulfilled)]: (state) => {
      state.bankData.saving = false;
      state.dataCompleted = true;
    },

    [String(getBanks.rejected)]: (state, action) => {
      state.bankLoading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-270');
    },
    [String(getBanks.pending)]: (state) => {
      state.bankLoading = true;
    },
    [String(getBanks.fulfilled)]: (state, action) => {
      state.bankLoading = false;
      state.banks = action.payload.data;
    },

    [String(getProfilePicture.rejected)]: (state, action) => {
      state.profilePicture.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-282');
    },
    [String(getProfilePicture.pending)]: (state) => {
      state.profilePicture.loading = true;
    },
    [String(getProfilePicture.fulfilled)]: (state, action) => {
      state.profilePicture.loading = false;
      state.profilePicture.file = action.payload.data;
    },

    [String(getRecords.rejected)]: (state, action) => {
      state.records.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-294');
    },
    [String(getRecords.pending)]: (state) => {
      state.records.loading = true;
    },
    [String(getRecords.fulfilled)]: (state, action) => {
      state.records.loading = false;
      state.records.file = action.payload.data;
    },

    [String(setRecords.fulfilled)]: (state) => {
      state.dataCompleted = true;
    },
    [String(setRecords.rejected)]: (state, action) => {
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-315');
    },

    [String(deleteProfilePicture.fulfilled)]: (state) => {
      state.profilePicture.file = undefined;
    },
    [String(deleteProfilePicture.rejected)]: (state, action) => {
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-319');
    },

    [String(setProfilePicture.fulfilled)]: (state) => {
      state.dataCompleted = true;
    },
    [String(setProfilePicture.rejected)]: (state, action) => {
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-320');
    },

    [String(getOtherDocs.rejected)]: (state, action) => {
      state.otherDocs.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-323');
    },
    [String(getOtherDocs.pending)]: (state) => {
      state.otherDocs.loading = true;
    },
    [String(getOtherDocs.fulfilled)]: (state, action) => {
      state.otherDocs.loading = false;
      state.otherDocs.files = action.payload.data;
    },

    [String(setOtherDocs.fulfilled)]: (state) => {
      state.dataCompleted = true;
    },
    [String(setOtherDocs.rejected)]: (state, action) => {
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-348');
    },

    [String(getOtherData.pending)]: (state) => {
      state.otherData.loading = true;
    },
    [String(getOtherData.rejected)]: (state, action) => {
      state.otherData.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-352');
    },
    [String(getOtherData.fulfilled)]: (state, action) => {
      state.otherData.loading = false;
      state.otherData.defaultData = action.payload?.data;
    },

    [String(putOtherData.pending)]: (state) => {
      state.otherData.responseLoading = true;
    },
    [String(putOtherData.rejected)]: (state, action) => {
      state.otherData.responseLoading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-365');
    },
    [String(putOtherData.fulfilled)]: (state) => {
      state.otherData.responseLoading = false;
      state.otherData.loading = false;
      state.dataCompleted = true;
    },

    [String(getAdditionalInfo.pending)]: (state) => {
      state.ppes.loading = true;
    },
    [String(getAdditionalInfo.fulfilled)]: (state, action) => {
      state.ppes.loading = false;
      state.ppes.defaultData = action.payload.data;
    },
    [String(getAdditionalInfo.rejected)]: (state, action) => {
      state.ppes.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-425');
    },

    [String(putPpes.pending)]: (state) => {
      state.ppes.status = RequestStatusEnum.PENDING;
    },
    [String(putPpes.fulfilled)]: (state) => {
      state.ppes.status = RequestStatusEnum.SUCCESS;
      state.dataCompleted = true;
    },
    [String(putPpes.rejected)]: (state, action) => {
      state.ppes.status = RequestStatusEnum.ERROR;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-CENTER_SLICE-425');
    },
  },
});

export const {
  clearCenterData,
  clearDataCompleted,
  clearStateCenter,
  setSelectedTab,
  setConfirmModalAction,
  setAlertTab,
  handleShowButtonActions,
} = centerSlice.actions;

export default centerSlice.reducer;
