/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  changeEmailRoute,
  getOauthTokenRoute,
  resendCreatePswdEmailRoute,
  sendRetreivePswdEmailRoute,
  logoutRoute,
  setNewPasswordRoute,
  setRetrievePasswordRoute,
  checkToken,
} from '@api/routes/auth';

import { sendLog } from '@utils/SentryError';
import LocalStorageService from '@utils/localStorage';
import { getStatusFromString, identifies } from '@utils/functions';

import server from '@api/index';
import Router from 'next/router';
import i18n from 'i18next';
import { AuthRequestResponseEnum } from '@utils/enums';

const localStorageService = LocalStorageService.getService();

export const initialState = {
  user: {},
  login: { loading: false, accountStatus: '', rejectedName: '', rejectedSupportEmail: '' },
  emailIsBlocked: false,
  email: '',
  accountTokenStatus: 'valid',
  setNewPasswordLoading: 'idle',
  resendEmailLoading: 'idle',
  editEmailError: 0,
  error: null,
  loading: false,
  logingOut: false,
  // Esta línea sirve para añadir estados al comienzo de un test de Cypress
  ...(typeof window !== 'undefined' &&
    typeof window.initialState !== 'undefined' &&
    window.initialState.authInitializer),
};

const _setUser = (state, action) => {
  const { _id, names, surnames, email, roles } = action.payload.data.user;
  identifies({ _id, names, surnames, email, roles });
  state.user = action.payload.data.user;
  if (action?.payload?.data?.user?.countryIso !== 'NN')
    i18n.changeLanguage(`es-${action.payload.data.user.countryIso}`);
  localStorageService.setToken({
    accessToken: action.payload.data.accessToken,
    refreshToken: action.payload.data.refreshToken,
  });
  localStorageService.setItem('user', JSON.stringify(action.payload.data.user));
};

export const logIn = createAsyncThunk(
  'auth/login',
  async (payload: { password: string; email: string }, thunkAPI) => {
    let { password, email } = payload;
    password = encodeURIComponent(password);
    email = encodeURIComponent(email);
    try {
      const response = await server({
        method: 'post',
        url: getOauthTokenRoute(),
        data: `grant_type=password&username=${email}&password=${password}`,
        auth: {
          username: process.env.NEXT_PUBLIC_USER,
          password: process.env.NEXT_PUBLIC_PASSWORD,
        },
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const registerApp = createAsyncThunk('auth/registerApp', async () => {
  const refreshToken = localStorageService.getRefreshToken();
  if (refreshToken && refreshToken !== 'undefined') {
    const response = await server({
      // Se uso este tipo de llamada pq no funcionaba con el post normal. En otros
      // requests usar el otro tipo de llamada server.post(...)
      method: 'post',
      url: getOauthTokenRoute(),
      data: `grant_type=refresh_token&refresh_token=${refreshToken}`,
      auth: {
        username: process.env.NEXT_PUBLIC_USER,
        password: process.env.NEXT_PUBLIC_PASSWORD,
      },
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    });
    return { ...response.data, type: 'refresh-token' };
  }
  const response = await server({
    // Se uso este tipo de llamada pq no funcionaba con el post normal. En otros
    // requests usar el otro tipo de llamada server.post(...)
    method: 'post',
    url: getOauthTokenRoute(),
    data: 'grant_type=client_credentials',
    auth: {
      username: process.env.NEXT_PUBLIC_USER,
      password: process.env.NEXT_PUBLIC_PASSWORD,
    },
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
});

export const loginFromLabours = createAsyncThunk(
  'auth/loginFromLabours',
  async (payload: { accessToken: string; refreshToken: string }) => {
    const response = await server({
      method: 'get',
      url: checkToken(),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${payload.accessToken}`,
      },
    });
    response.data.data.accessToken = payload.accessToken;
    response.data.data.refreshToken = payload.refreshToken;
    return response.data;
  }
);

export const resendEmail = createAsyncThunk(
  'auth/resendEmail',
  async (
    {
      email,
      captchaToken,
      type = 'newPswd',
    }: {
      email: string;
      captchaToken?: string;
      type?: 'newPswd' | 'retrievePswd';
    },
    thunkAPI
  ) => {
    let response;
    if (type === 'newPswd') response = await server.post(resendCreatePswdEmailRoute(), { email });
    else if (type === 'retrievePswd') {
      try {
        response = await server.post(sendRetreivePswdEmailRoute(), {
          email,
          captchaToken,
        });
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
    return response.data;
  }
);

export const setNewPassword = createAsyncThunk(
  'auth/createPassword',
  async (payload: { password: string; tempToken: string | string[] }) => {
    const response = await server.post(setNewPasswordRoute(), { ...payload });
    return { ...response, password: payload.password };
  }
);

export const changeEmail = createAsyncThunk(
  'auth/changeEmail',
  async (payload: { oldEmail: string; email: string }, thunkAPI) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await server({
        method: 'post',
        url: changeEmailRoute(),
        data: payload,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const setRetrievePassword = createAsyncThunk(
  'auth/retrievePassword',
  async (payload: { password: string; tempToken: string | string[] }) => {
    const response = await server.post(setRetrievePasswordRoute(), payload);
    return response.data;
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  const response = await server.delete(logoutRoute());
  return response;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: _setUser,
    clearLogout: (state) => {
      state.logingOut = false;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [String(logout.rejected)]: (state, action) =>
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-LOGOUT'),
    [String(logout.fulfilled)]: (state) => {
      state.user = {};
      state.logingOut = true;
    },
    [String(changeEmail.rejected)]: (state, action) => {
      const { status } = action.payload;
      if (status === 404) state.editEmailError = 404;
      else state.editEmailError = 1;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-237');
    },
    [String(changeEmail.fulfilled)]: (state, action) => {
      state.editEmailError = 0;
      state.email = action.payload.email;
    },
    [String(logIn.fulfilled)]: (state, action) => {
      state.login.loading = false;
      _setUser(state, action);
      state.login.accountStatus = 'success';
    },
    [String(logIn.pending)]: (state) => {
      state.login.loading = true;
      state.login.accountStatus = '';
    },
    [String(logIn.rejected)]: (state, action) => {
      const status = action?.payload?.status;
      if (status === 403) state.login.accountStatus = 'blocked';
      else if (status === 400 || status === 401 || status === 409)
        state.login.accountStatus = 'notFound';
      else if (status === 429)
        state.login.accountStatus = 'maxLoginRetries';
      else if (status === 412) {
        const { names, support } = action.payload.data.error.message;
        state.login.accountStatus = 'rejected';
        state.login.rejectedName = names;
        state.login.rejectedSupportEmail = support.email;
      } else sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-262');
      state.login.loading = false;
    },
    [String(loginFromLabours.fulfilled)]: (state, action) => {
      state.login.loading = false;
      _setUser(state, action);
      state.login.accountStatus = 'success';
    },
    [String(loginFromLabours.pending)]: (state) => {
      state.login.loading = true;
      state.login.accountStatus = '';
    },
    [String(loginFromLabours.rejected)]: (state, action) => {
      state.login.loading = false;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-276');
    },
    [String(registerApp.fulfilled)]: (state, action) => _setUser(state, action),
    [String(registerApp.rejected)]: (_, action) => {
      sendLog('Store', action.payload, 'Info', action.error, 'FE-AUTH_SLICE-62');
    },

    [String(setNewPassword.fulfilled)]: (state, action) => {
      state.setNewPasswordLoading = 'success';
      state.email = action.payload.data.data.email;
    },
    [String(setNewPassword.pending)]: (state) => {
      state.setNewPasswordLoading = 'loading';
    },
    [String(setNewPassword.rejected)]: (state, action) => {
      const status = getStatusFromString(action.error.message);
      state.setNewPasswordLoading = 'error';
      state.error = action.error;
      if (status === 409) {
        Router.push('/auth/log-in?status=alreadyActive');
      } else if (status === 404) state.accountTokenStatus = 'notFound';
      else sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-317');
    },

    [String(resendEmail.fulfilled)]: (state) => {
      state.resendEmailLoading = AuthRequestResponseEnum.SUCCESS;
    },
    [String(resendEmail.pending)]: (state) => {
      state.resendEmailLoading = 'loading';
    },
    [String(resendEmail.rejected)]: (state, action) => {
      const status = getStatusFromString(
        action?.payload?.message || action?.payload?.response?.status || action.error.message
      );
      if (status === 406) state.error = 'captchaError';
      else state.error = AuthRequestResponseEnum.REJECTED;
      state.resendEmailLoading = AuthRequestResponseEnum.ERROR;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-328');
    },

    [String(setRetrievePassword.fulfilled)]: (state) => {
      state.setNewPasswordLoading = 'success';
      setTimeout(() => Router.push('/auth/log-in'), 5000);
    },
    [String(setRetrievePassword.pending)]: (state) => {
      state.setNewPasswordLoading = 'loading';
    },
    [String(setRetrievePassword.rejected)]: (state, action) => {
      state.setNewPasswordLoading = 'error';
      state.error = action.error;
      const status = getStatusFromString(action.error.message);
      if (status === 404) state.accountTokenStatus = 'notFound';
      else sendLog('Store', action.payload, 'Fatal', action.error, 'FE-AUTH_SLICE-342');
    },
  },
});

export const { clearLogout, clearError } = authSlice.actions;

export default authSlice.reducer;
