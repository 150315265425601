/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistReducer,
  createTransform,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import LogRocket from 'logrocket';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import authReducer from './auth/authSlice';
import centerReducer from './center/centerSlice';
import locationReducer from './locations/locationSlice';
import componentsSlice from './components/componentsSlice';

const persistedStores = ['auth', 'center'];
const persistedKeys = { auth: ['user', 'email'], center: ['toDoSummary', 'doneSummary'] };

const reducers = combineReducers({
  auth: authReducer,
  center: centerReducer,
  location: locationReducer,
  components: componentsSlice,
});

const customTransform = createTransform(
  // Being saved to persist
  (state, key) =>
    Object.fromEntries(persistedKeys?.[key]?.map((subkey) => [subkey, state?.[subkey]])),
  // Being loaded to store
  (state) => state,
  // Filter only applied to persistStoreList
  { whitelist: persistedStores }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: persistedStores,
  transforms: [customTransform],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(LogRocket.reduxMiddleware()),
});

declare global {
  interface Window {
    Cypress: Record<string, unknown>;
    store: typeof store;
    initialState: {
      authInitializer?: Record<string, unknown>;
      centerInitializer?: Record<string, unknown>;
      locationInitializer?: Record<string, unknown>;
    };
  }
}

if (typeof window !== 'undefined' && window.Cypress) {
  window.store = store;
}

export default store;
