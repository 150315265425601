/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import server from '@api/index';
import { requirementsRoute } from '@api/routes/components';
import { sendLog } from '@utils/SentryError';
import { GetRequirementsPayload } from './d';

const initialState = {
  requirements: { docs: [], ppe: { docs: [], loading: false }, loading: false },
  error: undefined,
};

export const getRequirements = createAsyncThunk(
  'components/getRequirements',
  async (payload: GetRequirementsPayload) => {
    const response = await server.get(requirementsRoute(), { params: { ...payload } });
    return response.data;
  }
);

const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    clearCenterData: () => ({ ...initialState }),
  },
  extraReducers: {
    [getRequirements.pending.toString()]: (state, action) => {
      const { type } = action.meta.arg;
      if (type) state.requirements[type].loading = true;
      else state.requirements.loading = true;
    },
    [getRequirements.fulfilled.toString()]: (state, action) => {
      const { type } = action.meta.arg;
      if (type) {
        state.requirements[type].loading = false;
        state.requirements[type].docs = action.payload.data.docs;
      } else {
        state.requirements.loading = false;
        state.requirements.docs = action.payload.data.docs;
      }
    },
    [getRequirements.rejected.toString()]: (state, action) => {
      const { type } = action.meta.arg;
      if (type) state.requirements[type].loading = false;
      else state.requirements.loading = false;
      state.error = action.error;
      sendLog('Store', action.payload, 'Fatal', action.error, 'FE-COMPONENTS_SLICE-38');
    },
  },
});

export const { clearCenterData } = componentsSlice.actions;

export default componentsSlice.reducer;
