export const bankDataRoute = (): string => 'account/jobbers/banking';

export const getBanksRoute = (countryIso: string): string => `settings/banks/${countryIso}`;

export const getJobberCenterSettingRoute = (countryIso: string): string =>
  `settings/job-centers/${countryIso}`;

export const getJobberCenterSummary = (): string => 'account/jobbers/summary';

export const getOtherDataRoute = (): string => 'account/jobbers/other-data';

export const otherDocsRoute = (code?: string): string => {
  const url = 'account/jobbers/other-documents';
  if (code) return `${url}?code=${code}`;
  return url;
};
export const uploadOtherDocsRoute = (): string => 'account/jobbers/other-documents-full';

export const getAvailableJobsRoute = (): string => 'assignment/jobs/top-available';

export const personalDataRoute = (): string => 'account/jobbers/personal-data';

export const recordsRoute = (): string => 'account/jobbers/records';
export const recordUploadRoute = (): string => 'account/jobbers/records-full';

export const profilePhotoRoute = (): string => 'account/jobbers/profile-photo';

export const ppeRoute = (): string => 'account/jobbers/ppes';

export const getAdditionalDocsRoute = (): string => 'account/jobbers/additional-information';

export const vehiclesDocsRoute = (code: string, category?: string): string => {
  if (category) {
    return `account/jobbers/vehicle-documents?code=${code}&category=${category}`;
  }
  return `account/jobbers/vehicle-documents?code=${code}`;
};
export const uploadVehiclesDocsRoute = (code: string, category?: string): string => {
  if (category) {
    return `account/jobbers/vehicle-documents-full?code=${code}&category=${category}`;
  }
  return `account/jobbers/vehicle-documents-full?code=${code}`;
};
