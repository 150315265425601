import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';
import { hotjar } from 'react-hotjar';

// eslint-disable-next-line import/no-cycle
import server from '@api/index';
import { getSignedUrlS3Route } from '@api/routes/files';
import { IdentityCardTypesEnum, RequestStatusEnum } from './enums';

export const getStatusFromString = (txt: string) => {
  const matches = txt.match(/\d+/g);
  if (matches) return Number(matches[0]);
  return 0;
};

export const validateEmail = (txt: string) => {
  const isValid = txt.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if (isValid) return true;
  return false;
};

export const numberWithDotsAndCommas = (x) => {
  if (!x) return '';
  const str = x.toString().split('.');
  str[0] = str[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return str.join(',');
};

export const validateAge = (date: string): boolean => {
  const isValid = date.match(/\d{2}\/\d{2}\/\d{4}/);
  if (!isValid) return true;
  const userBirth = date ? moment().diff(moment(date, 'DD/MM/YYYY'), 'years', true) : undefined;
  if (userBirth && userBirth < 18) {
    return false;
  }
  return true;
};

export const validateRut = (rut: string): boolean => {
  const aux = rut.replace(/\./g, '');
  const rutFormat = /^([1-9][0-9]?)?([0-9]{3})?([0-9]{3})?-([0-9|k|K]{1})$/;
  if (!aux.match(rutFormat)) return true;

  const reversedRut = aux.split('').reverse();
  const validDigit =
    11 -
    (reversedRut
      .slice(2)
      .reduce((acc, item, index) => acc + parseInt(item, 10) * ((index % 6) + 2), 0) %
      11);

  if (validDigit === 11) return reversedRut[0] === '0';
  if (validDigit === 10) return reversedRut[0].toLowerCase() === 'k';

  return String(validDigit) === reversedRut[0];
};

export const placeMaskRut = (val: string): string => {
  if (!val) return '';
  const value = val.replace(/\.|-|(?![k|K])\D/g, '');
  if (value.length > 7) {
    return value.replace(/^([1-9][0-9]?)([0-9]{3})([0-9]{3})([0-9|k|K]{1})$/g, '$1.$2.$3-$4');
  }
  if (value.length > 4) {
    return value.replace(/^([0-9]{1,3})([0-9]{3})([0-9|k|K]{1})$/g, '$1.$2-$3');
  }
  if (value.length > 1) {
    return value.replace(/([0-9]{1,3})([0-9|k|K]{1})$/g, '$1-$2');
  }
  return value.replace(/^(\d)/, '$1');
};

export const placeMaskDate = (val: string): string => {
  if (!val) return '';
  const value = val.replace(/\/|\D/g, '');
  if (value.length > 4) {
    return value.replace(/^([0-9]{2})([0-9]{2})([0-9]{1,4})$/g, '$1/$2/$3');
  }
  if (value.length > 2) {
    return value.replace(/([0-9]{2})([0-9]+)$/g, '$1/$2');
  }
  return value.replace(/^(\d)/, '$1');
};

/*
  This function checks wether the keys that have obj2 have the same value than in obj1
*/
export const keysSameValues = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): boolean => {
  if (!obj1 || !obj2) return undefined;
  return Object.keys(obj2).every(
    (key) => (!obj2[key] && !obj1[key]) || (obj2[key] && obj1[key] && obj1[key] === obj2[key])
  );
};

export const getExtension = (name: string): string => {
  if (!name) return '';
  const str = name.split('.');
  return str.slice(-1)[0];
};

export const uploadFiles = async (
  file: File,
  fileType: string,
  urlToSave?: string,
  body?: Record<string, string>,
  returnKey?: boolean
): Promise<AxiosResponse<any>> => {
  const signedUrlResponse = await server.post(getSignedUrlS3Route(), { ext: fileType });
  const res = signedUrlResponse.data.data;
  await axios.put(res.url, file, { headers: { 'Content-Type': file.type } });
  if (returnKey || !urlToSave) return res;
  const response = await server.put(urlToSave, { key: res.key, ...body });
  return response;
};

export const getFiles = async (urlToResource: string): Promise<AxiosResponse<any>> => {
  const getFileStatus = await server.get(urlToResource);
  const { key } = getFileStatus.data.data;
  const accessToken = localStorage.getItem('access_token');
  if (!key) {
    Object.keys(getFileStatus.data.data).forEach((index) => {
      if (getFileStatus.data.data[index].key || getFileStatus.data.data[index].attachment) {
        getFileStatus.data.data[index].src = `${
          process.env.NEXT_PUBLIC_BASE_URL_API
        }/${getSignedUrlS3Route(
          getFileStatus.data.data[index].key || getFileStatus.data.data[index].attachment,
          accessToken
        )}`;
        getFileStatus.data.data[index].ext = getExtension(getFileStatus.data.data[index].key);
      }
    });
    return getFileStatus;
  }
  getFileStatus.data.data.src = `${process.env.NEXT_PUBLIC_BASE_URL_API}/${getSignedUrlS3Route(
    key,
    accessToken
  )}`;
  getFileStatus.data.data.ext = getExtension(key);
  return getFileStatus;
};

const imgToCanvas = (img: ImageBitmap): HTMLCanvasElement => {
  let canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  return canvas;
};

const calculateBrightness = (canvas: HTMLCanvasElement): number => {
  let ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;
  let colorSum = 0;

  for (let x = 0, len = data.length; x < len; x += 4) {
    let r = data[x];
    let g = data[x + 1];
    let b = data[x + 2];

    let avg = Math.floor((r + g + b) / 3);
    colorSum += avg;
  }

  return Math.floor(colorSum / (canvas.width * canvas.height));
};

export const fillCanvasWatermark = (img: ImageBitmap): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    let canvas = imgToCanvas(img);

    let ctx = canvas.getContext('2d');

    const diagonal = Math.sqrt(Math.pow(canvas.width, 2) + Math.pow(canvas.height, 2));
    const isBright = calculateBrightness(canvas) < 127.5;

    ctx.font = `${diagonal / 16}px Nunito Sans`;
    ctx.globalAlpha = isBright ? 0.45 : 0.25;
    ctx.fillStyle = isBright ? 'white' : 'black';
    ctx.textAlign = 'center';

    const xSpace = diagonal / 3.5,
      ySpace = (xSpace * canvas.height) / canvas.width;

    const maxLine =
      canvas.width / xSpace > canvas.height / ySpace
        ? canvas.width / xSpace
        : canvas.height / ySpace;

    for (let i = 1; i < maxLine + 1; i++) {
      let x = ySpace * i + xSpace,
        y = ySpace * i - ySpace;
      while (x <= canvas.width + xSpace && y >= 0) {
        paintText(canvas, ctx, x, y);
        x += xSpace;
        y -= ySpace;
      }
      x = ySpace * i;
      y = ySpace * i;
      while (x >= 0 && y <= canvas.height + ySpace) {
        paintText(canvas, ctx, x, y);
        x -= xSpace;
        y += ySpace;
      }
    }

    canvas.toBlob((blob) => resolve(blob));
  });
};

const paintText = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number
) => {
  const height = Math.sqrt(Math.pow(canvas.width, 2) + Math.pow(canvas.height, 2)) / 32,
    width = ctx.measureText('TimeJobs').width / 2;
  ctx.save();
  ctx.translate(x + height, y + width);
  ctx.rotate(Math.PI * 2 - Math.atan(canvas.height / canvas.width));
  ctx.translate(-(x + height), -(y + width));
  ctx.fillText('TimeJobs', x, y);
  ctx.restore();
};

/*
 * Polyfill for createImageBitmap
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/createImageBitmap
 *
 * Supports CanvasImageSource (img, video, canvas) sources, Blobs, and ImageData.
 *
 * From:
 * - https://dev.to/nektro/createimagebitmap-polyfill-for-safari-and-edge-228
 * - https://gist.github.com/MonsieurV/fb640c29084c171b4444184858a91bc7
 * Updated by:
 * - Yoan Tournade <yoan@ytotech.com>
 * - diachedelic, https://gist.github.com/diachedelic
 * - Paul Ellis, https://pseudosavant.com
 */

export function createImageBitmapIIFE(global) {
  function isCanvasImageSource(el) {
    const validElements = ['img', 'video', 'canvas'];

    return el && el.tagName && validElements.includes(el.tagName.toLowerCase());
  }

  function idealSize(currentValue, newValue, numerator, denominator) {
    if (typeof newValue === 'number') return newValue;
    if (typeof numerator !== 'number' || typeof denominator !== 'number') return currentValue;

    return (numerator / denominator) * currentValue;
  }

  if (!('createImageBitmap' in global)) {
    global.createImageBitmap = async function polyfillCreateImageBitmap(data, opts): Promise<any> {
      return new Promise((resolve, reject) => {
        opts = opts || {};

        let dataURL;
        const canvas = document.createElement('canvas');

        try {
          const ctx = canvas.getContext('2d');

          if (data instanceof Blob) {
            dataURL = URL.createObjectURL(data);
          } else if (isCanvasImageSource(data)) {
            const width = data.naturalWidth || data.videoWidth || data.clientWidth || data.width;
            const height =
              data.naturalHeight || data.videoHeight || data.clientHeight || data.height;
            canvas.width = idealSize(width, opts.resizeWidth, opts.resizeHeight, height);
            canvas.height = idealSize(height, opts.resizeHeight, opts.resizeWidth, width);

            ctx.drawImage(data, 0, 0, canvas.width, canvas.height);

            dataURL = canvas.toDataURL();
          } else if (data instanceof ImageData) {
            canvas.width = idealSize(data.width, opts.resizeWidth, opts.resizeHeight, data.height);
            canvas.height = idealSize(data.height, opts.resizeHeight, opts.resizeWidth, data.width);

            ctx.putImageData(data, 0, 0);

            dataURL = canvas.toDataURL();
          } else {
            reject(new Error('createImageBitmap does not handle the provided image source type'));
          }

          const img = new Image();
          img.onerror = reject;
          img.onload = () => resolve(img);
          img.src = dataURL;
        } finally {
          // avoid memory leaks on iOS Safari, see https://stackoverflow.com/a/52586606
          canvas.width = 0;
          canvas.height = 0;
        }
      });
    };
  }
}

// Must run in browser
export const loadIntercom = (settings: Intercom_.IntercomSettings): void => {
  window.intercomSettings = settings;
  (function () {
    const w: any = window;
    const ic: any = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${settings?.app_id}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const identifies = ({
  _id,
  names,
  surnames,
  email,
  roles,
}: {
  names: string;
  surnames: string;
  email: string;
  _id: string;
  roles: string[];
}): void => {
  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

  LogRocket.identify(_id, {
    name: `${names} ${surnames}`,
    roles: roles ? roles.join(', ') : '',
    email,
  });

  Sentry.setUser({ _id, email, roles, name: `${names} ${surnames}` });

  if (isProduction && email !== 'no-email@none.com') hotjar.identify(_id, { email });

  if ('Intercom' in window && email !== 'no-email@none.com') {
    window.intercomSettings = {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_KEY,
      hide_default_launcher: false,
    };
    Intercom('boot', {
      email,
      app_id: process.env.NEXT_PUBLIC_INTERCOM_KEY,
      name: `${names} ${surnames}`,
      user_id: _id,
    });
  }
};

export const updateIntercom = ({
  _id,
  email,
  telephone,
}: {
  _id: string;
  email: string;
  telephone: string;
}): void => {
  if ('Intercom' in window && email !== 'no-email@none.com') {
    window.intercomSettings = {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_KEY,
      hide_default_launcher: false,
    };

    Intercom('update', {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_KEY,
      phone: `+${telephone}`,
      user_id: _id,
    });
  }
};

export const clearState = (
  state,
  b: { payload: { key: string; subkey?: string; base?: string } }
) => {
  const { key, subkey, base } = b.payload ?? {};
  const originalState = base ?? RequestStatusEnum.IDLE;
  if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
  else if (key && state[key]) state[key] = originalState;
};

export const buildUrl = (key: string): string => {
  if (!key) return '';
  const accessToken = localStorage.getItem('access_token');
  return `${process.env.NEXT_PUBLIC_BASE_URL_API}/${getSignedUrlS3Route(key, accessToken)}`;
};

export const deleteSpacesSignInForm = (valuesAux: any): any => {
  valuesAux.names = valuesAux.names.trim();
  valuesAux.surnames = valuesAux.surnames.trim();
  return valuesAux;
};

export const validateEndingDocuments = (
  exp?: string,
  verifyDateParams?: any,
  subtractOneYear?: boolean
): { pendingExpiration: boolean; isExpired: boolean } => {
  const values = { pendingExpiration: false, isExpired: false };

  if (exp) {
    let initialDate = undefined;
    const { type, addQty, addUnit } = verifyDateParams || {};
    if ((addQty && addUnit && type === 'issueDate') || subtractOneYear) {
      initialDate = moment().subtract(
        subtractOneYear ? 1 : addQty,
        subtractOneYear ? 'year' : addUnit
      );
    }
    const date = moment(exp).startOf('day');
    const dayDiff = date.diff(moment(initialDate).startOf('day'), 'days');

    values.pendingExpiration = dayDiff <= 30 && dayDiff > 0;
    values.isExpired = date.isSameOrBefore(moment(initialDate).startOf('day'));
  }

  return values;
};

export const documentLabels = (translate, key: string, kind?: string) => {
  const title = translate.t(`center.uploadDocument.dateLabels.${kind || key}`);

  if (kind) {
    const labels = {
      [IdentityCardTypesEnum.ID]: { title, helper: 'expirationDate' },
      [IdentityCardTypesEnum.VISA_APPLICATION]: { title, helper: 'issueDate' },
      [IdentityCardTypesEnum.PTP]: { title, helper: 'expirationDate' },
      [IdentityCardTypesEnum.PEP]: { title, helper: 'issueDate' },
      [IdentityCardTypesEnum.FOREIGN_ID]: { title, helper: 'expirationDate' },
    };
    return labels[kind] || { title: '-----', helper: '-----' };
  }

  const labels = {
    id_card_front: { title, helper: 'expirationDate' },
    id_card_reverse: { title, helper: 'expirationDate' },
    suspension_certificate: { title, helper: 'expirationDate' },
    pension_certificate: { title, helper: 'expirationDate' },
    eps_certificate: { title, helper: 'expirationDate' },
    carInsurance: { title, helper: 'expirationDate' },
    carDriverLicense: { title, helper: 'expirationDate' },
    carRegistrationCertificate: { title, helper: 'expirationDate' },
    vaccinationCard: { title, helper: 'issueDate' },
    carOwnershipCard: { title, helper: 'expirationDate' },
    carSoat: { title, helper: 'expirationDate' },
    carRoadWorthinessTest: { title, helper: 'expirationDate' },
    healthCardWithFoodHandling: { title, helper: 'expirationDate' },
    motorcycleRegistrationCertificate: { title, helper: 'expirationDate' },
    motorcycleDriverLicense: { title, helper: 'expirationDate' },
    furgonRegistrationCertificate: { title, helper: 'expirationDate' },
    furgonDriverLicense: { title, helper: 'expirationDate' },
    motorcycleSoat: { title, helper: 'expirationDate' },
    motorcycleOwnershipCard: { title, helper: 'expirationDate' },
    vanRoadWorthinessTest: { title, helper: 'expirationDate' },
    vanDriverLicense: { title, helper: 'expirationDate' },
    vanOwnershipCard: { title, helper: 'expirationDate' },
    vanSoat: { title, helper: 'expirationDate' },
    foodHandlingCertificate: { title, helper: 'expirationDate' },
    epsCertificate: { title, helper: 'expirationDate' },
    pensionCertificate: { title, helper: 'expirationDate' },
    mopedRegistrationCertificate: { title, helper: 'expirationDate' },
    mopedDriverLicense: { title, helper: 'expirationDate' },
  };

  return labels[key] || { title: '-----', helper: '-----' };
};
